'use strict';

export default {
    isInSomeRole: (state) => (roles) => {
        return state.user.roles.some(x => roles.indexOf(x) >= 0);
    },
    isInRole: (state) => (role) => {
        return typeof state.user.roles.find(x => x === role) !== 'undefined';
    }
};
