'use strict';

import axios from 'axios';

const DEFAULT_GROUP_COLOR = '#17a2b8';
export const DEFAULT_STATUS_COLOR = '#f08000';

export async function fetchStatuses() {
    const response = await axios.get('/api/Control/GetAllStatuses');
    localStorage.setItem('control-statuses', JSON.stringify(response.data));
    return response.data;
}

export function getAll() {
    const all = JSON.parse(localStorage.getItem('control-statuses'));
    if (all) {
        return all;
    }

    return [];
};

export async function save(key, value) {
    return new Promise(async (resolve, reject) => {
        try {
            let all = getAll();
            all[key] = value;
            localStorage.setItem('control-statuses', JSON.stringify(all));
            resolve();
        } catch (e) {
            reject(e);
        }
    });
};

export const getStatusColor = function (statusId) {
    const all = getAll();
    const found = all.find(x => x.id === statusId);

    return found
        ? found.color
        : DEFAULT_STATUS_COLOR;
};

export const getGroupStatusColor = function (positions) {
    if (!positions || positions.length === 0) return DEFAULT_GROUP_COLOR;

    if (positions.every((x) => x.statusId === 10 || x.statusId === 15)) return getStatusColor(10);

    if (positions.some((x) => x.statusId === 1 || x.statusId === 2)) return getStatusColor(1);

    return DEFAULT_GROUP_COLOR;
};

export const getStatusName = function (statusId) {
    const all = getAll();
    const found = all.find(x => x.id === statusId);

    return found
        ? found.text
        : () => {
            throw `status '${statusId}' has not yet been implemented.`;
        };
};