'use strict';

import axios from 'axios';

export const get = async (controller, method, options) => {
  const response = await axios.get(`/api/${controller}/${method}`, options);
  return response;
};
export const post = async (controller, method, options) => {
  const response = await axios.post(`/api/${controller}/${method}`, options);
  return response;
};

export const remove = async (controller, method, options) => {
  const response = await axios.delete(`/api/${controller}/${method}`, options);
  return response;
};
