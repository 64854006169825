'use strict';

export default {
    getActivePartPositions(state) {
        const app = !state.activePartTab
            ? state.partPositions
            : state.partPositions
                .filter((x) => x.partId === state.activePartTab.id)
                .sort(
                    (a, b) =>
                        parseFloat(a.concatenatedNumbers) -
                        parseFloat(b.concatenatedNumbers)
                );
        return app;
    },
    getActivePartPositionsWithoutCoordinates(state) {
        const app = !state.activePartTab
            ? []
            : state.partPositions.filter(
                (x) =>
                    x.partId === state.activePartTab.id &&
                    (!x.coordinateX || !x.coordinateY)
            );
        return app;
    },
    nextPartPositionNumber(state) {
        const highestNumber = state.partPositions.reduce((entity, max) => {
            const parsed = parseInt(parseFloat(max.concatenatedNumbers).toFixed(0));
            if (entity > parsed) {
                return entity;
            } else {
                return parsed;
            }
        }, 0);

        return highestNumber + 1;
    },
    nextPartPositionSubNumber(state) {
        const highestNumber = state.partPositions.filter(x => x.partId === state.activePartTab.id).reduce((entity, max) => {
            const parsed = parseInt(parseFloat(max.subNumber).toFixed(0));
            if (entity > parsed) {
                return entity;
            } else {
                return parsed;
            }
        }, 0);

        if (isNaN(highestNumber))
            return 1;

        return highestNumber + 1;
    }
};
