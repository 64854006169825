'use strict';

import { get, post, remove } from '@/helpers/api';
import {
  getCoordinateTypeAsNumber,
  getNumberFromCocatenatedNumbers
} from '@/helpers/coordinate.helper';

export default {
  getBuildingParts({ commit }, buildingId) {
    get('BuildingPart', `GetAllByBuildingId/${buildingId}`).then((x) =>
      commit('SET_BUILDING_PART', x.data)
    );
  },
  getBuildingPartPositions({ commit }, buildingId) {
    get('BuildingPartPosition', `GetAllForListByBuildingId/${buildingId}`).then(
      (x) => commit('SET_BUILDING_PART_POSITIONS', x.data)
    );
  },
  /**
   * Set is position.isPositionNotInAnySchedule
   * @param {object} {id: number, isPositionNotInAnySchedule: bool}
  */
  setIsPositionNotInAnySchedule({ commit }, obj) {
    commit('SET_IS_POSITION_NOT_IN_ANY_SCHEDULE', obj);
  },
  setActivePartTab({ commit }, partId) {
    commit('SET_ACTIVE_PART_TAB', partId);
  },
  openPartPositionSidebar({ commit }, partPosition) {
    commit('OPEN_PART_POSITION_SIDEBAR', partPosition);
  },
  clearPartPositionSidebar({ commit }) {
    commit('CLEAR_PART_POSITION_SIDEBAR');
  },
  placeCoordinatesOnDrawing({ commit }, position) {
    commit('SET_PLACE_COORDINATES_ON_DRAWING', position);
  },
  /**
   * Set symbol size
   * @param {object} {id: number, symbolSize: decimal(2,1)}
  */
  async setPartSymbolSize({ commit }, obj) {
      post(
        'BuildingPart',
        `SetSymbolSize/${obj.id}`,
        obj
      ).then((x) => {
      commit('SET_ACTIVE_PART_SYMBOL_SIZE', obj.symbolSize);
    });
  },
  /**
   * Aborting place position on drawing
   * @param {number} positionId
   */
  async abortPlacePositionOnDrawing({ commit }, positionId) {
    await remove(
      'BuildingPartPosition',
      `DeleteCoordinates/${positionId}`
    ).then((x) => {
      commit('ABORT_PLACE_POSITION_ON_DRAWING');
    });
  },
  async clearCoordinates({ commit }, positionId) {
    await remove(
      'BuildingPartPosition',
      `DeleteCoordinates/${positionId}`
    ).then((x) => {
      commit('CLEAR_POSITION_COORDINATE', positionId);
    });
  },
  removeBuildingPartPosition({ commit }, id) {
    return new Promise((resolve, reject) => {
      remove('BuildingPartPosition', `Delete/${id}`).then(
        (x) => {
          commit('REMOVE_BUILDING_PART_POSITIONS', id);
          resolve(x);
        },
        (error) => reject(error)
      );
    });
  },
  saveBuildingPartPosition({ commit }, position) {
    return new Promise((resolve, reject) => {
      post('BuildingPartPosition', `Save/${position.partId}`, position).then(
        (x) => {
          position.id = parseInt(x.data.entityId);
          position.number = getNumberFromCocatenatedNumbers(
            position.concatenatedNumbers,
            'before'
          );
          position.subNumber = getNumberFromCocatenatedNumbers(
            position.concatenatedNumbers,
            'after'
          );
          commit('SAVE_BUILDING_PART_POSITIONS', position);
          resolve(x);
        },
        (error) => reject(error)
      );
    });
  },
  /**
   * Saves coordinates.
   * @param {object} obj {id, coordinateX, coordinateY, coordinateInfoX, coordinateInfoY, type, isGroup}
   */
  async savePartsCoordinates({ commit }, obj) {
    await post('BuildingPartPosition', `SaveCoordinates/${obj.id}`, {
      ...obj,
      type: getCoordinateTypeAsNumber(obj.type) // Overwrites type as an "enum".
    }).then((x) => {
      commit('SET_PARTS_POSITION', obj);
    });
  },
  saveBuildingPart({ commit }, part) {
    return new Promise((resolve, reject) => {
      post('BuildingPart', `Save/${part.buildingId}`, part).then(
        (x) => {
          commit('SAVE_BUILDING_PART', part);
          resolve(x);
        },
        (error) => reject(error)
      );
    });
  },
  openPartSidebar({ commit }, part) {
    commit('OPEN_PART_SIDEBAR', part);
  },
  deleteBuildingPart({ commit }, partId) {
    return new Promise((resolve, reject) => {
      remove('BuildingPart', `Delete/${partId}`).then(
        (x) => {
          commit('DELETE_BUILDING_PART', partId);
          resolve(x);
      },
      (error) => reject(error)
    );
  })
  }
};
