'use strict';

export default {
  SET_ISLOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_USER(state, user) {
    state.user = { ...user.user, ...user.organizationLogotypes };
    state.isLoading = false;
  },
  SET_CUSTOMER(state, customer) {
    state.selectedCustomer = customer;
  },
  SET_USER_ORGANIZATION(state, organizationId){
    state.user.organizationId = organizationId;
  },
  SET_IS_OFFLINE(state, val) {
    state.isOffline = val;
  }
};
