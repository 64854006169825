'use strict';

export default {
    SET_IS_LOADING(state, val) {
        state.isLoading = val;
    },
    SET_IS_CHECKING_IN(state, val) {
        state.isCheckingIn = val;
    },
    SET_ACTIVE_CONTROL(state, control) {
        state.active = control;
        state.isLoading = false;
    },
    SET_CONTROL_PART_POSITION(state, vm) {
        const existing = state.active.positions.find(x => x.id === vm.id)
        existing.statusId = vm.statusId
        existing.lackDescription = vm.lackDescription;
    },
    SET_TO_DONE(state, vm) {
        state.active.status = 10;
        state.active.notes = vm.controlNotes;
    },
    SET_NOTES(state, notes) {
        state.active.notes = notes;
    },
    ABORT_CHECK_IN(state) {
        if (!state.active)
            return;
        state.active.checkedOut = false;
        state.active.checkedOutBy = null;
        state.active.checkedOutDate = null;
    },
    CHECK_OUT_CONTROL(state, vm) {
        state.active.checkedOut = vm.checkedOut;
        state.active.checkedOutBy = vm.checkedOutBy;
        state.active.checkedOutDate = vm.checkedOutDate;
        state.active.controlId = vm.controlId;
    },
    //SET_NEW_REVISION_DATE(state, vm) {
    //    const existing = state.active.positions.find(x => x.positionId === vm.buildingPartPositionId);
    //    existing.nextRevisionDate = vm.newValue.nextRevisionDate;
    //    existing.nextRevisionDateDisplayText = vm.newValue.nextRevisionDateDisplayText;
    //},
    SET_ALL_POSITION_STATUSES_TO_DONE(state, positionIds) {
        positionIds.forEach(positionId => {
            const position = state.active.positions.find(x => x.id === positionId);
            Object.assign(position, {
                statusId: 10
            });
        });
    }
};
