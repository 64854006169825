'use strict';

import actions from '@/store/modules/ControlModule/actions';
import mutations from '@/store/modules/ControlModule/mutations';

export const controlModule = {
  namespaced: true,
  state: {
    isLoading: true,
    isCheckingIn:false,
    active: null
  },
  actions,
  mutations
};