'use strict';
import {
    ACCEPTED_FILE_TYPES,
    acceptedImageExtensions
} from '@/helpers/file.helper';

export const ENTITY_TYPES = {
    customer: {
        id: 0,
        displayText: 'kund',
        allowedExtensions: ACCEPTED_FILE_TYPES,
        name: 'customer'
    },
    archive: {
        id: 1,
        displayText: 'innehåll',
        allowedExtensions: ACCEPTED_FILE_TYPES,
        name: 'archive'
    },
    article: {
        id: 3,
        displayText: 'artikel',
        allowedExtensions: ACCEPTED_FILE_TYPES,
        name: 'article'
    },
    symbol: {
        id: 7,
        displayText: 'symbol',
        allowedExtensions: ['.svg'],
        name: 'symbol'
    },
    controlPartPosition: {
        id: 12,
        displayText: 'kontrollposition',
        allowedExtensions: acceptedImageExtensions,
        name: 'controlPartPosition'
    },
    organization: {
        id: 13,
        displayText: 'organisation',
        allowedExtensions: acceptedImageExtensions,
        name: 'organization'
    },
    controlNote: {
        id: 14,
        displayText: 'kontrollanteckning',
        allowedExtensions: ACCEPTED_FILE_TYPES,
        name: 'controlNote'
    }
};

export const getEntityTypeById = function (entityType) {
    const found = Object.values(ENTITY_TYPES).find((x) => x.id === entityType);
    return found
        ? found
        : () => {
            throw `EntityType '${entityType}' has not yet been implemented.`;
        };
};

export const getDisplayText = function (entityType) {
    return getEntityTypeById(entityType).displayText;
};
