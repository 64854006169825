'use strict';

import axios from 'axios';

export default {
    getMenu({ commit, rootState }) {
        rootState.user.selectedCustomer
            ? axios
                .get(
                    `/api/Archive/GetMenuItemsByCustomerId?id=${rootState.user.selectedCustomer.id}`,
                    { withCredentials: true }
                )
                .then((response) => commit('SET_MENU', response.data))
            : commit('SET_MENU', []);
    }
};
