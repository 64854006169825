'use strict';

import Vue from 'vue';

// This filter is ONLY for C# DateTimes. Doesn't work with JavaScript Dates.
Vue.filter('datetime', function (value, convertTo) {
  function getDate(val) {
    const months = [
      { index: '01', name: 'januari' },
      { index: '02', name: 'februari' },
      { index: '03', name: 'mars' },
      { index: '04', name: 'april' },
      { index: '05', name: 'maj' },
      { index: '06', name: 'juni' },
      { index: '07', name: 'juli' },
      { index: '08', name: 'augusti' },
      { index: '09', name: 'september' },
      { index: '10', name: 'oktober' },
      { index: '11', name: 'november' },
      { index: '12', name: 'december' }
    ];
    let month = val.substr(5, 2);
    let year = val.substr(0, 4);
    let day = val.substr(8, 2).startsWith('0')
      ? val.substr(9, 1)
      : val.substr(8, 2); // Remove first 0.
    return `${day} ${months.find((m) => m.index == month).name} ${year}`;
  }
  function getTime(val, formatting) {
    switch (formatting) {
      case 'HH:mm':
        return val.substr(0, 5);
      case 'HH:mm:ss':
        return val.substr(0, 8);
    }
  }
  if (value) {
    // Valid input = yyyy-MM-dd OR yyyy-MM-ddTHH:mm:ss OR yyyy-MM-dd HH:mm:ss
    let splitted =
      value.indexOf('T') > -1 ? value.split('T') : value.split(' ');
    switch (convertTo) {
      // d MMMM yyyy:
      case 'd MMMM yyyy':
        return getDate(splitted[0]);
      case 'd MMMM yyyy HH:mm':
        return getDate(splitted[0]) + ' ' + getTime(splitted[1], 'HH:mm');
      case 'd MMMM yyyy HH:mm:ss':
        return getDate(splitted[0]) + ' ' + getTime(splitted[1], 'HH:mm:ss');
      // yyyy-MM-dd:
      case 'dd':
        return splitted[0].substring(8, 1);
      case 'MM':
        return splitted[0].substring(5, 1);
      case 'yyyy':
        return splitted[0].substring(0, 4);
      case 'yyyy-MM-dd':
        return splitted[0];
      case 'yyyy-MM-dd HH:mm':
        return splitted[0] + ' ' + getTime(splitted[1], 'HH:mm');
      case 'yyyy-MM-dd HH:mm:ss':
        return splitted[0] + ' ' + getTime(splitted[1], 'HH:mm:ss');
    }
    // Something went snett :(
    return 'Något gick snett med datumkonvertering :(';
  }
});
