'use strict';

export const groupByKey = function (array, key) {
  const result = {};
  array.forEach((item) => {
    if (!result[item[key]]) result[item[key]] = [];
    result[item[key]].push(item);
  });
  return result;
};

/**
 * Filter-map. Like map, but skips undefined values.
 *
 * @param callback
 */
export const fmap = function(callback) {
    return this.reduce((accum, ...args) => {
        let x = callback(...args);
        if (x !== undefined)
            accum.push(x);
        return accum;
    }, []);
}