<template>
    <div v-if="user.user && !user.isLoading"
         class="text-center">
        <router-link to="/"
                     v-if="user.user.organizationId > 0">
            <img v-if="user.user.logotypeFileId > 0 && !isMenuCollapsed"
                 :class="{ collapsed: isMenuCollapsed }"
                 :src="getImgUrl(false)"
                 alt="Logotyp" />
            <img v-if="user.user.smallLogotypeFileId > 0 && isMenuCollapsed"
                 :class="{ collapsed: isMenuCollapsed }"
                 :src="imgUrl"
                 alt="Logotyp" />
            <font-awesome-icon v-else-if="!(user.user.smallLogotypeFileId > 0) && isMenuCollapsed"
                               icon="fire"
                               size="3x"
                               class="text-primary"
                               style="margin: 5px;"/>
        </router-link>
    </div>
</template>

<script>
    import { getDownloadUrl } from '@/helpers/file.helper';

    export default {
        name: 'MenuLogotype',
        props: {
            isMenuCollapsed: Boolean,
            user: Object
        },
        methods: {
            getImgUrl(small) {
                return `${getDownloadUrl(small ? this.user.user.smallLogotypeFileId : this.user.user.logotypeFileId)}?v=${Math.random()}`;
            }
        },
        computed: {
            imgUrl() {
                return `${this.getImgUrl(this.isMenuCollapsed)}`;
            }
        }
    };
</script>

<style scoped>
    img {
        margin: 15px 0px;
        max-width: 100%;
        padding: 0 4px;
    }

        img.collapsed {
            max-width: 50px;
            margin: 10px 0px;
        }
</style>
