'use strict';

export const ACCEPTED_IMAGE_TYPES = [
    {
        extension: '.tif',
        type: 'image/tif'
    },
    {
        extension: '.jpg',
        type: 'image/jpg'
    },
    {
        extension: '.jpeg',
        type: 'image/jpeg'
    },
    {
        extension: '.tiff',
        type: 'image/tiff'
    },
    {
        extension: '.gif',
        type: 'image/gif'
    },
    {
        extension: '.svg',
        type: 'image/svg+xml'
    },
    {
        extension: '.webp',
        type: 'image/webp'
    },
    {
        extension: '.png',
        type: 'image/png'
    }
];

// Should mirror Skydda.SBA.WebApp\appsettings.json - FileSettings['AllowedExtensions'].
export const ACCEPTED_FILE_TYPES = [
    '.png',
    '.jpg',
    '.jpeg',
    '.gif',
    '.xlsx',
    '.pptx',
    '.docx',
    '.rtf',
    '.txt',
    '.svg'
];

export const convertToBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const getIcon = function (extension) {
    switch (extension) {
        case '.pdf':
        case '.pdfx':
            return 'file-pdf';
        case '.eps':
        case '.gif':
        case '.jpg':
        case '.jpeg':
        case '.pct':
        case '.psd':
        case '.png':
        case '.ps':
        case '.tiff':
        case '.bmp':
            return 'file-image';
        case '.mov':
        case '.mpg':
        case '.mpeg':
        case '.avi':
            return 'file-video';
        case '.rar':
        case '.zip':
        case '.tar':
            return 'file-archive';
        case '.xls':
        case '.xlsx':
        case '.xltx':
        case '.xlt':
            return 'file-excel';
        case '.pptx':
        case '.ppsx':
        case '.potx':
        case '.ppt':
        case '.pps':
        case '.pot':
            return 'file-powerpoint';
        case '.doc':
        case '.dot':
        case '.docx':
        case '.dotx':
            return 'file-word';
        case '.rtf':
        case '.txt':
            return 'file-alt';
        default:
            return 'file';
    }
};

export const getDownloadUrl = function (id) {
    return `${process.env.VUE_APP_ROOT_API}File/Download/${id}`;
};

export const getDownloadUrlByArticleId = function (id) {
    return `${process.env.VUE_APP_ROOT_API}File/Article/${id}`;
};

export const formatBytes = function (bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const isAcceptedImage = function (file) {
    if (!file.type.startsWith('image/')) return false;

    return ACCEPTED_IMAGE_TYPES.some((x) => x.type === file.type.toLowerCase());
};

export const isImageContentType = function (type) {
    return ACCEPTED_IMAGE_TYPES.some((x) => x.type === type.toLowerCase());
};

export const acceptedImageExtensions = function () {
    return ACCEPTED_IMAGE_TYPES.map((x) => x.extension).toString();
};

export const getExtension = function (filename) {
    return filename.substring(filename.lastIndexOf('.') + 1, filename.length) || filename;
};

export const base64toBlob = function (base64) {
    return new Promise(resolved => {
        let byteString;
        if (base64.split(',')[0].indexOf('base64') >= 0)
            byteString = atob(base64.split(',')[1]);
        else
            byteString = unescape(base64.split(',')[1]);

        const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];

        const ia = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([ia], { type: mimeString });
        resolved(blob);
    });
};