'use strict';
import { db } from '@/plugins/dexie/dbDoControl';
import { get } from '@/helpers/api';

export const articleService = {
    getAllArticlesForControl,
    getFirst
}

async function getAllArticlesForControl(controlId) {
    const articles = await get('Article', `GetAllForControl/${controlId}`).then(x => x.data);
    return await addArticles(articles);
}

async function addArticles(articles) {
    return await db.articles.bulkPut(articles);
}

export async function getFirst(id) {
    return await db.articles.where({ id: id }).first();
}