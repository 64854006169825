<template>
    <div v-if="user.user && !user.isLoading">
        <router-link to="/"
                     v-if="user.user.organizationId > 0">
            <img v-if="user.user.logotypeFileId > 0"      
                 :src="getImgUrl(false)"
                 alt="Logotyp" />        
            <font-awesome-icon v-else
                               icon="fire"
                               size="3x"
                               class="text-primary"
                               style="margin: 5px;"/>
        </router-link>
    </div>
</template>

<script>
    import { getDownloadUrl } from '@/helpers/file.helper';

    export default {
        name: 'MenuLogotype',
        props: {
            isMenuCollapsed: Boolean,
            user: Object
        },
        methods: {
            getImgUrl(small) {
                return `${getDownloadUrl(small ? this.user.user.smallLogotypeFileId : this.user.user.logotypeFileId)}?v=${Math.random()}`;
            }
        }
    };
</script>

<style scoped>
    img {
        max-width: 300px;
        max-height: 40px;
    }
</style>
