'use strict';
import { db } from '@/plugins/dexie/dbDoControl';
import { get, post, remove } from '@/helpers/api';
import { getExtension } from '@/helpers/file.helper';

export const service = {
    getFirst,
    checkIn,
    setControlPartPosition,
    setNewRevisionDate,
    checkOut,
    getFileById,
    openFile,
    getFileByEntityTypeAndEntityId,
    addFiles,
    removeFile,
    setFileDescription,
    getAll,
    add,
    addChange,
    getAllFilesForControl,
    abortCheckIn,
    setControlNotes,
    setToDone,
    setAllPositionsToDone,
    getAllByCustomerId,
    checkInAll
}


export async function checkInAll() {
    const controls = await db.controls.toArray();

    const result = await Promise.all(
        controls.map(async control => {
            if ((await isCheckedOut(control.id)).data) {
                try {
                    await checkIn(control.id);
                } catch (e) {
                    return {
                        error: e + ' Egenkontrollens schemanamn: ' + control.scheduleName,
                        control: control
                    };
                }
            }
            else {
                // Remove control from db tables.
                await db.transaction('rw', [db.changes, db.files, db.controls], async () => {
                    await db.changes.where('controlId').equals(control.id).delete(); // Remove changes.
                    await db.files.where({ controlId: control.id }).delete(); // Remove db files.
                    await db.controls.where('id').equals(control.id).delete(); // Remove db controls.
                });

                return {
                    error: `Egenkontroll med schemanamn '${control.scheduleName}' har redan checkats in.`,
                    control: control
                };
            }
        })
    );

    // Returns errors.
    return result.filter(x => x);
}

async function setAllPositionsToDone(control, partId) {
    if (!control.checkedOut)
        await post('Control', `SetAllControlPositionStatusesToDone/${control.id}?controlPartId=${partId ? partId : ''}`)

    let changes = [];
    let positionsWithNewStatus = control.positions.map((position) => {
        if (position.statusId === 0) {
            if (!partId) {
                position.statusId = 10;
                changes.push(position);
                return position.id;
            }
            else {
                if (position.controlPartId === partId) {
                    position.statusId = 10;
                    changes.push(position);
                    return position.id;
                }
            }
        }
    }).filter(x => x !== undefined);

    if (!control.checkedOut)
        return positionsWithNewStatus;

    // Add to db if offline.
    const newDate = new Date();
    await db.transaction('rw', [db.changes, db.controls], async () => {
        await changes.forEach(async x => {
            await db.changes.add({
                controlId: control.id,
                date: newDate,
                type: 'setControlPartPosition',
                entityId: x.id,
                data: x,
                event: 'update'
            });
            const positionsId = x.id;
            await db.controls
                .where({ id: control.id })
                .modify(x => {
                    let pos = x.positions.find(y => y.id === positionsId);
                    pos.statusId = 10;
                });
        });
    });

    return positionsWithNewStatus;
}

async function setToDone(controlId, done, controlNotes) {
    return await post('Control', `SetToDone/${controlId}`, {
        done: done,
        controlNotes: controlNotes
    });
}

async function setControlNotes(isOffline, controlId, controlNotes) {
    if (!isOffline)
        return await post('Control', `SetNotes/${controlId}`, { id: controlId, notes: controlNotes })
    else {
        await db.controls
            .where({ id: controlId })
            .modify(x => x.notes = controlNotes);
        return await addChange(controlId, new Date(), 'controlNote', controlId, controlNotes, null);
    }
}

async function abortCheckIn(controlId) {
    await db.transaction('rw', [db.changes, db.files, db.controls], async () => {
        // Changes.
        await db.changes
            .where('controlId')
            .equals(controlId)
            .delete();

        // Files.
        await db.files
            .where({ controlId: controlId })
            .delete();

        // Control.
        await db.controls.where({ id: controlId }).delete();
    });

    await post('Control', `AbortCheckIn/${controlId}`);
}

async function checkIn(controlId) {
    try {
        // Get changes.
        let cc = await db.changes
            .where('controlId')
            .equals(controlId)
            .toArray();

        // Post.
        await Promise.all(cc.map(async change => {
            await handleChange(change);
        }));
    } catch {
        return 'Misslyckades med att hantera förändringar!';
    }

    try {
        // Update tables.
        await db.transaction('rw', [db.changes, db.files, db.controls], async () => {
            await db.changes.where('controlId').equals(controlId).delete(); // Remove changes.
            await db.files.where({ controlId: controlId }).delete(); // Remove db files.
            await db.controls.where('id').equals(controlId).delete(); // Remove db controls.
        });
    } catch {
        return 'Misslyckades med databasuppdatering!';
    }

    try {
        // Post checkedIn.
        await checkInControl(controlId);
    } catch {
        return 'Misslyckades med incheckning!';
    }

    return true;
}

async function checkOut(currentControl) {
    const response = await post('Control', `CheckOut/${currentControl.id}`);
    const updatedControl =
    {
        ...currentControl,
        ...response.data
    };
    await add(updatedControl);
    await getAllFilesForControl(currentControl.id);

    return response.data;
}

function getEntityTypeForFileType(type) {
    switch (type) {
        case 'controlPartPositionFile':
            return 12;
        case 'controlNoteFile':
            return 14;
    }

    throw 'no entity type corresponding with file type';
}

async function handleChange(change) {
    switch (change.type) {
        case 'setControlPartPosition':
            return await setControlPartPosition(false, change.controlId, change.data);
        case 'setNewRevisionDate':
            return await setNewRevisionDate(change.data.buildingPartPositionId, change.data.years);
        case 'controlPartPositionFile':
        case 'controlNoteFile':
            switch (change.event) {
                case 'added':
                    return await uploadBlobAndSetFileIdToRealId(getEntityTypeForFileType(change.type), change.entityId, change.data.file, change.data.fileName, change.data.fileId);
                case 'removed':
                    return await removeFileFromSql(change.data.id);
                case 'setDescription':
                    // TODO: transaction over table.changes instead.
                    alert('not implemented');
                    //return await saveFileDescription(change.data);
                    break;
            }
        case 'controlNote':
            return await setControlNotes(false, change.controlId, change.data);
    };
}

async function getAll() {
    return await db.controls.toArray();
}

async function getFirst(id) {
    return await db.controls.where({ id: id }).first();
}

async function getAllByCustomerId(id) {
    return await db.controls.where({ customerId: id }).toArray();
}

async function add(control) {
    return await db.controls.add(control);
}

/* SETS */
async function setControlPartPosition(isOffline, controlId, data) {
    if (isOffline) {
        await db.transaction('rw', [db.changes, db.controls], async () => {
            // Add change.
            await addChange(
                controlId,
                new Date(),
                'setControlPartPosition',
                data.id,
                data,
                'update'
            );
            // Update control.
            await db.controls
                .where({ id: controlId })
                .modify(x => {
                    let pos = x.positions.find(y => y.id === data.id);
                    pos.statusId = data.statusId;
                    pos.lackDescription = data.lackDescription;
                });
        });

        return;
    }

    return await post('Control', `SetControlPartPosition/${data.id}`, data);
};

async function setNewRevisionDate(isOffline, controlId, buildingPartPositionId, years) {
    if (isOffline) {
        // Calculate NextRevisionDate.
        let nextRevisionDate = new Date();
        nextRevisionDate.setFullYear(nextRevisionDate.getFullYear() + parseInt(years));

        await addChange(
            controlId,
            new Date(),
            'setNewRevisionDate',
            buildingPartPositionId,
            {
                buildingPartPositionId: buildingPartPositionId,
                years: years,
                nextRevisionDate: nextRevisionDate
            },
            'update'
        );

        return {
            data: {
                nextRevisionDate: nextRevisionDate,
                nextRevisionDateDisplayText: nextRevisionDate.toLocaleDateString('sv-SE')
            }
        }
    }

    return await post('Control', `SetNewRevisionDate/${buildingPartPositionId}?years=${years}`);
}

async function checkInControl(controlId) {
    return await post('Control', `CheckIn/${controlId}`);
}

async function isCheckedOut(controlId) {
    return await post('Control', `IsCheckedOut/${controlId}`);
}

/* ./sets */

/* FILES */
async function uploadBlobAndSetFileIdToRealId(entityType, entityId, blob, fileName, fileId) {
    let data = new FormData();
    data.append('files', blob, fileName);
    const response = await post(
        'File',
        `SaveSingle?entityType=${entityType}&entityId=${entityId}`,
        data
    );
    const realSqlId = response.data;
    return await db.files.update(fileId, { fileId: realSqlId });
}

async function getFileById(id) {
    return await db.files
        .where('id')
        .equals(id)
        .first();
}

async function removeFile(controlId, fileId, entityId) {
    // Get file.
    const file = await db.files.get({ id: fileId });
    // Delete file.
    await db.files.where({ id: fileId }).delete();
    // Has been added while offline, if so, remove from Changes.
    if (file.changeId) {
        await db.changes.where({ id: file.changeId }).delete();
        return;
    }

    // Else add to change.
    await addChange(controlId, new Date(), 'controlPartPositionFile', entityId, { id: fileId }, 'removed');
}

async function getFileByEntityTypeAndEntityId(entityType, entityId) {
    return await db.files
        .where({ entityType: entityType, entityId: entityId }).toArray();
}

async function addFile(controlId, entityType, entityId, file) {
    const fileId = parseInt(Date.now().toString() + Math.floor((Math.random() * 100) + 1).toString())
    const fileInfo = {
        created: null,
        createdBy: null,
        description: null,
        entityId: entityId,
        entityType: entityType,
        fileContentType: file.type,
        fileExtension: getExtension(file.name),
        fileName: file.name,
        fileSize: file.size,
        id: fileId,
        controlId: controlId
    };
    const fileAsBase64 = {
        file: file,
        fileClientType: 'blob',
        fileName: file.name,
        fileId: fileId
    };

    const typeOfFile = entityType === 12 ? 'controlPartPositionFile' : 'controlNoteFile';
    const changeId = await addChange(controlId, new Date(), typeOfFile, entityId, fileAsBase64, 'added');

    const fileInfoAndBlob = {
        ...fileInfo,
        ...fileAsBase64,
        changeId
    };

    await db.files.add(fileInfoAndBlob);
}

async function addFiles(data) {
    await db.transaction('rw', [db.files, db.changes], async () => {
        // TODO: Promise all constructor is anti-pattern.
        return await Promise.all(
            await data.files.map(async (x) => {
                await addFile(data.controlId, data.entityType, data.entityId, x)
            })
        );
    });
}

async function openFile(id) {
    await db.files
        .where('id')
        .equals(id)
        .first()
        .then(x => {
            const url = window.URL.createObjectURL(x.blob);
            window.open(url, '_blank');
            window.URL.revokeObjectURL(url);
        });
}

async function getAllFilesForControl(controlId) {
    const fileInfo = await get('File', `GetAllForControl/${controlId}`).then(x => x.data);
    fileInfo.forEach((fileInfo) => {
        fetch(`/api/File/Download/${fileInfo.id}`)
            .then(file => {
                file.blob().then(x => {
                    const fileInfoAndBlob = {
                        ...fileInfo,
                        file: x,
                        fileClientType: 'blob',
                        controlId: controlId
                    };
                    db.files.add(fileInfoAndBlob);
                });
            });
    });
}

async function removeFileFromSql(id) {
    await remove('File', `Remove/${id}`);
}

async function setFileDescription(controlId, descriptionForm) {
    throw 'not yet implemented correctly.';
    // Set description in iDB.
    await db.files.update(descriptionForm.id, { description: descriptionForm.description });
    // Add to changes.
    await addChange(controlId, new Date(), 'controlPartPositionFile', descriptionForm.id, descriptionForm, 'setDescription');
}

async function saveFileDescription(data) {
    const file = await db.files.get({ id: data.id });
    // TODO: Needs to be in a transaction to work properly.
    const realId = file.fileId;
    await post('File', `SetDescription`, { id: realId, description: data.description });
}
/* ./ files */


/* HELPERS */
async function addChange(controlId, date, type, entityId, data, event) {
    return await db.changes.add({
        controlId: controlId,
        date: date,
        type: type,
        entityId: entityId,
        data: data,
        event: event
    });
};
/* ./ helpers */