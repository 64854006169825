'use strict';

import Router from 'vue-router';

let router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'startPage',
            meta: {
                title: 'Översikt',
            },
            component: () => import(/*webpackChunkName: "startPage" */ '@/views/StartPage.vue')
        },
        {
            path: '/page/:id?',
            name: 'page',
            component: () => import(/*webpackChunkName: "page" */ '@/views/ArchivePage.vue')
        },
        {
            path: '/admin/manage/users/save',
            name: 'admin-manage-user-save',
            component: () => import(/* webpackChunkName: "admin-manage-user-save" */ '@/views/admin/manage/user/SaveUser.vue')
        },
        {
            path: '/admin/manage/users',
            name: 'admin-manage-user-list',
            meta: {
                title: 'Användare',
            },
            component: () => import(/* webpackChunkName: "admin-manage-user-list" */ '@/views/admin/manage/user/ListUsers.vue')
        },
        {
            path: '/admin/manage/roles/save',
            name: 'admin-manage-role-save',
            meta: {
                title: 'Skapa roll',
            },
            component: () => import(/* webpackChunkName: "admin-manage-role-save" */ '@/views/admin/manage/role/SaveRole.vue')
        },
        {
            path: '/admin/manage/roles',
            name: 'admin-manage-role-list',
            meta: {
                title: 'Roller',
            },
            component: () => import(/* webpackChunkName: "admin-manage-role-list" */ '@/views/admin/manage/role/ListRoles.vue')
        },
        {
            path: '/admin/manage/customers',
            name: 'admin-manage-customer-list',
            meta: {
                title: 'Kunder',
            },
            component: () => import(/* webpackChunkName: "admin-manage-customer-list" */ '@/views/admin/manage/customer/ListCustomers.vue')
        },
        {
            path: '/admin/manage/customers/save',
            name: 'admin-manage-customer-save',
            component: () => import(/* webpackChunkName: "admin-manage-customer-save" */ '@/views/admin/manage/customer/SaveCustomer.vue')
        },
        {
            path: '/admin/manage/articles',
            name: 'admin-manage-article-list',
            meta: {
                title: 'Artiklar',
            },
            component: () => import(/* webpackChunkName: "admin-manage-article-list" */ '@/views/admin/manage/article/ListArticles.vue')
        },
        {
            path: '/admin/manage/organizations',
            name: 'admin-manage-organizations-list',
            meta: {
                title: 'Organisationer',
            },
            component: () => import(/* webpackChunkName: "admin-manage-organizations-list" */ '@/views/admin/manage/organization/ListOrganizations.vue')
        },
        {
            path: '/admin/manage/articles/save',
            name: 'admin-manage-article-save',
            component: () => import(/* webpackChunkName: "admin-manage-article-save" */ '@/views/admin/manage/article/SaveArticle.vue')
        },
        {
            path: '/admin/manage/archives',
            name: 'admin-manage-archive-list',
            meta: {
                title: 'Innehåll',
            },
            component: () => import(/* webpackChunkName: "admin-manage-archive-list" */ '@/views/admin/manage/archive/ListArchive.vue')
        },
        {
            path: '/admin/manage/files/:entityType/:entityId?',
            name: 'admin-manage-files-list',
            meta: {
                title: 'Filer',
            },
            component: () => import(/* webpackChunkName: "admin-manage-symbols-list" */ '@/views/admin/manage/file/ListFiles.vue')
        },
        {
            path: '/manage/buildings',
            name: 'manage-building-list',
            meta: {
                title: 'Fastigheter',
            },
            component: () => import(/* webpackChunkName: "manage-building-list" */ '@/views/manage/building/ListBuildings.vue')
        },
        {
            path: '/manage/buildings/:id/:tab?/:entityId?',
            name: 'manage-building',
            component: () => import(/* webpackChunkName: "manage-building" */ '@/views/manage/building/Building.vue')
        },
        {
            path: '/import/buildings/:id?',
            name: 'import-buildings',
            component: () => import(/* webpackChunkName: "import-buildings" */ '@/views/import/ImportBuildings.vue')
        },
        {
            path: '/control/:id',
            name: 'do-control',
            component: () => import(/* webpackChunkName: "do-control" */ '@/views/control/DoControl.vue')
        },
        {
            path: '/error/:statusCode/:statusText?',
            name: 'error',
            meta: {
                title: 'Ett fel har inträffat',
            },
            component: () => import( /* webpackChunkName: "error-view" */ '@/views/Error.vue')
        },
        {
            path: '/do-control/print/:controlId/:partId?',
            name: 'control-print-view',
            component: () => import(/* webpackChunkName: "control-print-view" */ '@/components/control/ControlPrintView.vue')
        },
        {
            path: '*',
            name: '404-default',
            redirect: (to) => {
                return `/Error/404/${encodeURIComponent('Sidan saknas.')}`;
            }
        }
    ]
});


import Vue from 'vue';
import store from '@/store';
const DEFAULT_TITLE = 'SeBrA';
router.afterEach((to) => {
    Vue.nextTick(() => {
        var arr = [];
        if (store.state.user.selectedCustomer)
            arr.push(store.state.user.selectedCustomer.name);

        if (to.meta.title)
            arr.push(to.meta.title);

        document.title = `${arr.join(' / ')} - ${DEFAULT_TITLE}`;
    });
});

export default router;