'use strict';

const SETTINGS = {
  pagination: {
    selectedPageSize: 10,
    options: [5, 10, 25, 50, 100, 500]
    },
    isOffline: false
};

function createSettings() {
  localStorage.setItem('settings', JSON.stringify(SETTINGS));
};

export function getByKey(key) {
  const one = getAll()[key] || null;
  return one;
};

export function getAll() {
  const all = JSON.parse(localStorage.getItem('settings'));
  if (!all) {
    createSettings();
    return SETTINGS;
  }

  return all;
};

export function save(key, value) {
    return new Promise((resolve, reject) => {
        try {
            let all = getAll();
            all[key] = value;
            localStorage.setItem('settings', JSON.stringify(all));
            resolve();
        } catch (e) {
            reject(e);
        }
    });
};