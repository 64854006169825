'use strict';

export const getCoordinateTypeAsNumber = function (coordinateTypeAsString) {
  switch (coordinateTypeAsString) {
    case 'all':
      return -1;
    case 'coordinates':
      return 0;
    case 'coordinatesInfo':
      return 1;
  }
};

export const getNumberFromCocatenatedNumbers = function (numbers, direction) {
  if (numbers.indexOf(',') === -1 && numbers.indexOf('.') === -1) {
    if (direction === 'after') return null;
    else return parseInt(numbers);
  }

  var splitted = numbers.split(',').length === 0 || numbers.split('.');
  switch (direction) {
    case 'before':
      return parseInt(splitted[0]);
    case 'after':
      return parseInt(splitted[1]);
  }
};

export const setBuildingPartPositionCoordinatesFromLowestPositionNumber =
  function (position, arr) {
    //if ((position.coordinateX || position.coordinateY) || (position.coordinateInfoX || position.coordinateInfoY))
    //    return;

    var positionWithLowestNumber = arr
      .filter((x) => x.number === position.number && x.id !== position.id)
      .sort((a, b) => {
        return (
          parseFloat(a.concatenatedNumbers) - parseFloat(b.concatenatedNumbers)
        );
      });
    if (!positionWithLowestNumber[0]) return;

    // Set values.
    if (!position.coordinateX || !position.coordinateY) {
      position.coordinateX = positionWithLowestNumber[0].coordinateX;
      position.coordinateY = positionWithLowestNumber[0].coordinateY;
    }
    if (!position.coordinateInfoX || !position.coordinateInfoY) {
      position.coordinateInfoX = positionWithLowestNumber[0].coordinateInfoX;
      position.coordinateInfoY = positionWithLowestNumber[0].coordinateInfoY;
    }
  };