'use strict';
import Dexie from 'dexie';

export const db = new Dexie('dbDoControl');

db.version(2).stores({
    changes: '++id, controlId, date, type, entityId, data, event',
    files: '&id, [entityId+entityType], controlId, fileId',
    controls: '&id, *parts, *positions, customerId',
    articles: '&id, fileId'
});

db.version(1).stores({
    changes: '++id, controlId, date, type, entityId, data, event',
    files: '&id, [entityId+entityType], controlId, fileId',
    controls: '&id, *parts, *positions, customerId'
});